$red--primary: #dc0000;
$red--secondary: #ffb0b0;

$purple--primary: #710cff;
$purple--secondary: #dac3fa;

.red {
    header {
        background-color: $red--primary;

        div:nth-child(3) {
            background-color: $red--secondary;
        }
    }
    button {
        background-color: $red--primary;
        border-color: $red--primary;
    }
    footer {
        background-color: $red--primary;
    }
}

.purple {
    header {
        background-color: $purple--primary;

        div:nth-child(3) {
            background-color: $purple--secondary;
        }
    }
    button {
        background-color: $purple--primary;
        border-color: $purple--primary;
    }
    footer {
        background-color: $purple--primary;
    }
}